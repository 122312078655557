<template>
  <button class="search-button" @click="aa">搜索</button>
</template>

<script>
export default {
  name: 'buttonSearch',
  methods: {
    aa(){
      this.$emit("oo")
    }
  }
};
</script>


<style scoped>
.search-button {
  padding: 11px 20px; /* 调整 padding 以适应按钮大小 */
  border: 1px solid #e2e0e0; /* 添加 2px 宽度的边框并设置颜色为蓝色 (#007bff)，可以根据需求修改颜色 */
  border-radius: 5px;
  background-color: #efefef; /* 按钮的背景颜色，可以根据需求修改 */
  color: #1e2f02; /* 按钮文本颜色 */
  cursor: pointer;
  font-size: 14px; /* 调整字体大小 */
  outline: none;
}

/* 添加过渡效果，使按钮在鼠标悬停时有更自然的反馈 */
.search-button:hover {
  background-color: #6986a6; /* 鼠标悬停时的背景颜色 */
  transition: background-color 0.3s ease;
}
</style>
