<template>
  <div>
    <div ref="map" class="map"></div>


    <div class="search-layout">


      <div class="search-box" >
        <div class="icon-container">
          <i v-if="!searchQuery" class="fas fa-search"></i>
          <i v-if="searchQuery" @click="clearSearch" class="fas fa-times"></i>
        </div>
        <input
          v-model="searchQuery"
          type="text"
          placeholder="中文船名/MMSI/IMO"
          class="search-input"
          @keyup.enter="searchBoat"
        />
        <button-search @oo="searchBoat"></button-search>
        <!-- <button @click="figureOutTrack" style="position: fixed; top:20%; left:1%;">轨迹插件</button>
        <button @click="clearGroup" style="position: fixed; top:25%; left:1%;">清除插件</button> -->
      </div>






      <div v-if="selectedItem && selectedItem.name == searchQuery && isShip" class="info-box">
        <div class="category-bar">
          <div :class="{'category-item': true, 'active': currentTab === 'current'}"
               @mouseover="hoverTab('current')"
               @mouseleave="leaveTab"
               @click="changeTab('current')">当前</div>
          <div :class="{'category-item': true, 'active': currentTab === 'history'}"
               @mouseover="hoverTab('history')"
               @mouseleave="leaveTab"
               @click="changeTab('history')">历史</div>
          <!-- <div :class="{'category-item': true, 'active': currentTab === 'plan'}"
               @mouseover="hoverTab('plan')"
               @mouseleave="leaveTab"
               @click="changeTab('plan')">计划</div> -->
          <!-- <div :class="{'category-item': true, 'active': currentTab === 'info'}"
               @mouseover="hoverTab('info')"
               @mouseleave="leaveTab"
               @click="changeTab('info');getBoatImgURL()">资料</div> -->
        </div>



        <div v-if="currentTab === 'current'">
<!--          <div v-if="Object.keys(selectedItem).length > 10" class="detail-row">-->
          <div v-if="'mmsi' in selectedItem" class="detail-row">
            <!-- 船舶信息 -->
            <div class="update-time">
              <!--              🔈 <p style="color: coral; font-size: 0.8em">上次更新时间: {{ boatNewInfo.time }}</p>-->
              🔈<p style="color: #ffffff; font-size: 0.8em" v-if="isLog">上次更新时间: {{ formatDate(boatNewInfo.time) }}</p>
<!--              <el-button style="position: absolute; top: 45px; right: 10px;" type="success">加入船队</el-button>-->
              <!-- 3.13 -->
              <el-dropdown trigger="click"  @command="addBoatToFleet">
                <!-- 直接应用定位样式于 el-button，不需要 slot 属性 -->
                <!-- <el-button style="position: absolute; top: -18px; right: -170px;" type="success">加入船队</el-button> -->

                <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
                  <el-dropdown-item
                    v-for="(fleet, index) in shipList"
                    :key="index"
                    :command="index">
                    {{ fleet.name }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <p style="color: coral; font-size: 0.8em" v-if="!isLog">🔒请登录以查看更多内容</p>

            </div>
            <div class="more-choose" style="">
              <h4 style="color: #2a3f7e;margin-left: 6px;margin-right: 20px;">静态信息</h4>
              <!-- <div class="button-container">
                <el-row>
                  <el-button type="primary" plain @click="aroudBoat">周边船</el-button>
                  <el-button type="primary" @click="judgeIfLog" plain>船位报</el-button>
                  <el-button type="primary" @click="judgeIfLog" plain>访船</el-button>
                  <el-button type="primary" plain>居中</el-button>
                  <el-button type="primary" plain>分享</el-button>
                </el-row>
              </div> -->
            </div>
            <div class="flex-container">
              <div class="leftHalf">
                <p class="item-row">
                  <span class="item-label">船名:</span>
                  <span class="item-value">{{ translateFlag(boatNewInfo.shipname) }}</span>
                </p>

                <p class="item-row">
                  <span class="item-label">IMO:</span>
                  <span class="item-value">{{ selectedItem.imo }}</span>
                </p>
                <p class="item-row">
                  <span class="item-label">船长:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.length }}米</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">船宽:</span>
                  <span class="item-value" v-if="isLog">{{boatNewInfo.width}}米</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <!-- <p class="item-row">
                  <span class="item-label">航速:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.sog }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">预计到达时间:</span>
                  <span class="item-value" v-if="isLog">{{ (boatNewInfo.eta / 3600).toFixed(2) }}h</span>
                  <span class="item-value" v-if="!isLog">🔒</span>

                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">目的港口:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.dest }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">经度:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.x }}°</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
              </div>
              <div class="rightHalf">
                <p class="item-row">
                  <span class="item-label">MMSI:</span>
                  <span class="item-value">{{ boatNewInfo.mmsi }}</span>
                </p>
                <p class="item-row">
                  <span class="item-label">类型:</span>
                  <span class="item-value" v-if="isLog">{{ translateType(boatNewInfo.ship_type) }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <!-- <p class="item-row">
                  <span class="item-label">吃水深度:</span>
                  <span class="item-value" v-if="isLog">{{boatNewInfo.draught }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <p class="item-row">
                  <span class="item-label">呼号:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.callsign }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">母港:</span>
                  <span class="item-value" v-if="isLog">{{ selectedItem.homeport }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <!-- <p class="item-row">
                  <span class="item-label">状态:</span>
                  <span class="item-value" v-if="isLog">{{ translateStatus(boatNewInfo.nav_status) }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">纬度:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.y }}°</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
              </div>
            </div>
            <div class="more-choose" style="background-color: rgba(255,  255, 255, 0.9);" >
              <p style="color: #2a3f7e;margin-left: 6px;margin-right: 0px; font-size: 16px; font-weight: bolder;">快捷轨迹</p>
              <div class="button-container">
                <el-row>
                  <el-button type="primary" @click="handlerConfirm('day')" plain>近一天</el-button>
                  <el-button type="primary" @click="handlerConfirm('week')" plain>近一周</el-button>
                  <el-button type="primary" @click="handlerConfirm('month')" plain>近一月</el-button>
                  <el-button type="primary" @click="handlerConfirm('threeMonths')" plain>近三月</el-button>
                  <!-- <el-button type="success" @click="judgeIfLog" plain>更多..</el-button> -->
                </el-row>
              </div>
            </div>

            <!-- <div class="update-time">
              🔈<p style="color: coral; font-size: 0.8em" v-if="isLog">这里需要展示的信息</p>
              <p style="color: coral; font-size: 0.8em" v-if="!isLog">🔒请登录以查看更多内容</p>
            </div> -->

            <div class="more-choose">
              <p style="color: #2a3f7e;margin-left: 6px;margin-right: 20px; font-size: 16px; font-weight: bolder;">最新动态</p>
              <!-- <div class="button-container">
                <el-row>
                  <el-button type="primary" @click="judgeIfLog" plain>加入船队</el-button>
                </el-row>
              </div> -->
            </div>
            <div class="flex-container">
              <div class="leftHalf">
                <p class="item-row">
                  <span class="item-label">船旗:</span>
                  <span class="item-value">{{ translateFlag(boatNewInfo.flag) }}</span>
                </p>
                <p class="item-row">
                  <span class="item-label">航速:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.sog }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">船首向:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.true_head }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">预计到达时间:</span>
                  <span class="item-value" v-if="isLog">{{ (boatNewInfo.eta / 3600).toFixed(2) }}h</span>
                  <span class="item-value" v-if="!isLog">🔒</span>

                </p>
                <p class="item-row">
                  <span class="item-label">目的港代号:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.dest }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">经度:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.x }}°</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
              </div>
              <div class="rightHalf">
                <p class="item-row">
                  <span class="item-label">吃水深度:</span>
                  <span class="item-value" v-if="isLog">{{boatNewInfo.draught }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">状态:</span>
                  <span class="item-value" v-if="isLog">{{ translateStatus(boatNewInfo.nav_status) }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">航迹向:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.cog / 10.0 }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">目的地:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.dest_en}}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>

                </p>
                <p class="item-row">
                  <span class="item-label">目的地中文:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.dest_cn}}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">纬度:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.y }}°</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
              </div>
            </div>

            <!-- <div class="to-space">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
                <br>待完善区域<br>.</p>

            </div> -->

            <!-- <div class="more-choose">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">航线气象</p>
              <div class="button-container">
                <el-row>
                  <el-button type="primary" @click="judgeIfLog" plain>打开气象数据</el-button>
                </el-row>
              </div>
            </div>
            <div class="to-space">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
                <br>待完善区域<br>.</p>

            </div> -->
          </div>


          <div v-else>
            <!-- 港口信息 -->
          </div>

        </div>








        <div v-if="currentTab === 'history'">
          <!-- 历史功能栏的内容 -->
          <div class="more-choose">
            <p style="color: #2a3f7e;margin-left: 6px;margin-right: 20px; font-size: 16px;">简述</p>
          </div>
          <div class="flex-container">
            <div class="leftHalf">
                <p class="item-row">
                  <span class="item-label">IMO:</span>
                  <span class="item-value">{{ selectedItem.imo }}</span>
                </p>
                <p class="item-row">
                  <span class="item-label">船旗:</span>
                  <span class="item-value">{{ translateFlag(boatNewInfo.flag) }}</span>
                </p>
                <p class="item-row">
                  <span class="item-label">船长:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.length }}米</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">船宽:</span>
                  <span class="item-value" v-if="isLog">{{boatNewInfo.width}}米</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <!-- <p class="item-row">
                  <span class="item-label">航速:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.sog }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">预计到达时间:</span>
                  <span class="item-value" v-if="isLog">{{ (boatNewInfo.eta / 3600).toFixed(2) }}h</span>
                  <span class="item-value" v-if="!isLog">🔒</span>

                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">目的港口:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.dest }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">经度:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.x }}°</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
              </div>
              <div class="rightHalf">
                <p class="item-row">
                  <span class="item-label">MMSI:</span>
                  <span class="item-value">{{ boatNewInfo.mmsi }}</span>
                </p>
                <p class="item-row">
                  <span class="item-label">类型:</span>
                  <span class="item-value" v-if="isLog">{{ translateType(boatNewInfo.ship_type) }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <!-- <p class="item-row">
                  <span class="item-label">吃水深度:</span>
                  <span class="item-value" v-if="isLog">{{boatNewInfo.draught }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <p class="item-row">
                  <span class="item-label">呼号:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.callsign }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <p class="item-row">
                  <span class="item-label">母港:</span>
                  <span class="item-value" v-if="isLog">{{ selectedItem.homeport }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p>
                <!-- <p class="item-row">
                  <span class="item-label">状态:</span>
                  <span class="item-value" v-if="isLog">{{ translateStatus(boatNewInfo.nav_status) }}</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
                <!-- <p class="item-row">
                  <span class="item-label">纬度:</span>
                  <span class="item-value" v-if="isLog">{{ boatNewInfo.y }}°</span>
                  <span class="item-value" v-if="!isLog">🔒</span>
                </p> -->
              </div>
          </div>
          <div class="more-choose" style="background-color: rgba(255, 255, 255, 1);" >
            <p style="color: #2a3f7e;margin-left: 6px;margin-right: 20px; font-size: 16px;">轨迹</p>
            <div class="button-container">
              <el-row>
                <el-button @click="setDateRange('day')" type="primary" plain>近一天</el-button>
                <el-button @click="setDateRange('week')" type="primary" plain>近一周</el-button>
                <el-button @click="setDateRange('month')" type="primary" plain>近一月</el-button>
                <el-button @click="setDateRange('threeMonths')" type="primary" plain>近三月</el-button>
              </el-row>
            </div>
          </div>
          <div class="block">
            <span class="demonstration">请选择查询范围</span>
            <el-date-picker
              class="custom-date-picker"
              v-model="valueDataHistory"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions">
            </el-date-picker>

          </div>
          <div>
            <el-row class="el-two-button">
              <el-button  type="primary" @click="ifMoreThanSeven" style="margin-top: 20px;width: 60px;font-size: 13px;" >查 询</el-button>
              <el-button  type="primary" @click="clearDateRange" style="margin-top: 20px;width: 60px;font-size: 13px;" >清 空</el-button>
            </el-row>
          </div>
          <!-- <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">船舶报告</p>
            <div class="button-container">
              <el-row>
                <el-button @click="judgeIfLog" type="primary"  plain>筛选</el-button>
                <el-button @click="judgeIfLog" type="primary" plain>管理</el-button>
              </el-row>
            </div>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">航次</p>
            <div class="button-container">
              <el-row>
                <el-button @click="judgeIfLog" type="primary"  plain>导出</el-button>
              </el-row>
            </div>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">挂靠</p>
            <div class="button-container">
              <el-row>
                <el-button @click="judgeIfLog" type="primary"  plain>导出</el-button>
              </el-row>
            </div>
          </div>

          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div> -->

        </div>






        <div v-if="currentTab === 'plan'">
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">计划</p>
            <div class="button-container">
              <el-row>
                <el-button type="primary" @click="judgeIfLog" plain>航程航向</el-button>
                <el-button type="primary" @click="judgeIfLog" plain>转向点</el-button>
                <el-button type="primary" @click="judgeIfLog" plain>管理</el-button>
              </el-row>
            </div>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">安排</p>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">船位</p>
            <div class="button-container">
              <el-row>
                <el-button type="primary" @click="judgeIfLog" plain>启用</el-button>
              </el-row>
            </div>
          </div>

          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <!-- 计划功能栏的内容 -->
        </div>




        <div v-if="currentTab === 'info'">
          <!-- 信息功能栏的内容 -->
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">船舶</p>
            <div class="button-container">
              <el-row>
                <el-button type="primary" @click="judgeIfLog" plain>CLL</el-button>
                <el-button type="primary" @click="judgeIfLog" plain>上传船舶图片</el-button>
              </el-row>
            </div>
          </div>
          <div class="image-slider">
            <el-carousel :interval="3000" trigger="hover" arrow="always">
              <el-carousel-item v-for="image in this.images" :key="image">
                <img :src="image" alt="船舶图片" style=" width: 100%; height: 100%;object-fit: cover; ">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">资料</p>
            <div class="button-container">
              <el-row>
                <el-button type="primary" @click="goToMoreInfo" plain >更多</el-button>
              </el-row>
            </div>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>

        </div>



      </div>


      <div v-if="selectedItem && selectedItem.name == searchQuery && !isShip" class="info-box">
        <div class="category-bar">
          <div :class="{'category-item': true, 'active': currentTab === 'current'}"
               @mouseover="hoverTab('current')"
               @mouseleave="leaveTab"
               @click="changeTab('current')">信息</div>
          <div :class="{'category-item': true, 'active': currentTab === 'history'}"
               @mouseover="hoverTab('history')"
               @mouseleave="leaveTab"
               @click="changeTab('history')">气象</div>
          <div :class="{'category-item': true, 'active': currentTab === 'plan'}"
               @mouseover="hoverTab('plan')"
               @mouseleave="leaveTab"
               @click="changeTab('plan')">服务</div>
          <div :class="{'category-item': true, 'active': currentTab === 'info'}"
               @mouseover="hoverTab('info')"
               @mouseleave="leaveTab"
               @click="changeTab('info')">船舶</div>
        </div>



        <div v-if="currentTab === 'current'">
          <div v-if="Object.keys(selectedItem).length < 10" class="detail-row">
            <!-- 港口信息 -->
            <div class="more-choose">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px;font-size: 14px">公开留言</p>
              <div class="button-container">
                <el-row>
                  <el-button style="width: 60px" type="primary" plain @click="judgeIfLog">添加</el-button>
                  <!--                <el-button type="primary" plain>船位报</el-button>-->
                  <!--                <el-button type="primary" plain>访船</el-button>-->
                  <!--                <el-button type="primary" plain>居中</el-button>-->
                  <!--                <el-button type="primary" plain>分享</el-button>-->
                </el-row>
              </div>
            </div>
            <div class="flex-container">
              <div class="leftHalf">
                <br><br><br>
                <p style="color: #888888;margin-left: 6px;margin-right: 20px;font-size: 14px">待完善</p>
                <br><br><br>
              </div>

            </div>

            <div class="more-choose">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 15px;">航行警告</p>
              <div class="button-container">
                <el-row>
                  <el-button type="primary" plain @click="judgeIfLog">港口指南</el-button>
                </el-row>
              </div>
            </div>

            <div class="to-space">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
                <br>待完善区域<br>.</p>

            </div>

            <div class="more-choose">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">航线气象</p>
              <div class="button-container">
                <el-row>
                  <el-button type="primary" plain @click="judgeIfLog">打开气象数据</el-button>
                </el-row>
              </div>
            </div>
            <div class="to-space">
              <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
                <br>待完善区域<br>.</p>

            </div>
          </div>


          <div v-else>
            <!-- 港口信息 -->
          </div>

        </div>








        <div v-if="currentTab === 'history'">
          <!-- 历史功能栏的内容 -->
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">预报</p>
            <div class="button-container">
              <el-row>

                <el-button type="primary" plain @click="judgeIfLog">地图居中</el-button>
              </el-row>
            </div>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">历史</p>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>


        </div>






        <div v-if="currentTab === 'plan'">
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">服务商</p>
            <div class="button-container">
              <el-row>
                <el-button type="primary" @click="judgeIfLog" plain>标注/认证</el-button>
              </el-row>
            </div>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <!-- 计划功能栏的内容 -->
        </div>




        <div v-if="currentTab === 'info'">
          <!-- 信息功能栏的内容 -->
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">在港</p>
          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>
          <div class="more-choose">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">预抵</p>

          </div>
          <div class="to-space">
            <p style="color: #888888;margin-left: 6px;margin-right: 20px; font-size: 13px;">
              <br>后续功能待补充<br>.</p>
          </div>

        </div>



      </div>
    </div>


    <div class="infoBox" v-if="getMyPartInfo">
      <div class="upPart">
        <p style="z-index: 1002; color: #ffffff;font-size: 16px;padding: 5px 10px 05px 10px; ">用户：{{userName}}</p>
      </div>
      <div class="downPart">
        <el-row class="el-two-button22">
          <el-button style="width: 80px;height: 25px;font-size: 12px" type="primary" @click="toMyInfo">个人中心</el-button>
          <el-button style="width: 80px;height: 25px;font-size: 12px" type="primary" @click="confirmLogout">退出登录</el-button>
        </el-row>
      </div>

    </div>



    <div class="sidebar" v-if="isVisible">
      <button class="close-btn" @click="closeSidebar">✖</button>
      <div style=" ">
        <p style="text-align: center;color: #ffffff;font-size: 14px;">🔍搜索结果：{{tempQuery}}
          <!-- <span class="extra-spacing"></span>共找到 <span style=" font-size: 16px ;font-weight: bolder ;color: rgb(18, 212, 18);">{{searchResults.locationList.length + searchResults.shipInfoList.length}}</span> 条结果</p> -->
          <span class="extra-spacing"></span>共找到 <span style=" font-size: 16px ;font-weight: bolder ;color: rgb(18, 212, 18);">{{searchResults.shipInfoList.length}}</span> 条结果</p>
          <hr style="width: 100%; border: 2px solid #4f796a;">
      </div>
      <div class="vf-div">
        <!-- 遍历 shipInfoList -->
        <div v-for="(ship, index) in searchResults.shipInfoList" :key="ship.id" class="search-result" @click="updateSearchQuery(ship)">
          <p>{{ index + 1 }}. 船舶<strong>: <span style="margin-right: 10px;"></span> {{ ship.name }}</strong>
            <span class="extra-spacing"></span>{{translateFlag(ship.flag)}}<span class="extra-spacing"></span>{{ translateType(ship.type) }}</p>
          <p>MMSI: {{ ship.mmsi }}<span style="margin-right: 40px;"></span>IMO: {{ ship.imo }}</p>
        </div>


        <!-- 遍历 locationList -->
        <!-- <div v-for="(location, index) in searchResults.locationList" :key="location.id" class="search-result" @click="updatePortSearchQuery(location)">
          <p>{{ searchResults.shipInfoList.length + index + 1 }}. 港口<strong>: <span style="margin-right: 10px;"></span>{{ location.name }}</strong></p>
          <p>中文名:<strong>{{ location.cnName || 'NULL' }}</strong></p>
        </div> -->
      </div>
    </div>

    <div class="sidebar" v-if="isVisible2">
      <button class="close-btn" @click="closeSidebar">✖</button>
      <div>
        <p style="text-align: center; color: #ffffff;font-size: 15px;">{{this.searchQuery}}:周边船</p>
        <hr style="width: 100%; border: 1px solid #888888;">
        <div class="more-choose">
          <div class="button-container-two">
            <el-row>
              <el-button @click="setDistance(1)" type="primary" plain>1海里</el-button>
              <el-button @click="setDistance(5)" type="primary" plain>5海里</el-button>
              <el-button @click="setDistance(10)" type="primary" plain>10海里</el-button>
              <el-button @click="setDistance(15)" type="primary" plain>15海里</el-button>
            </el-row>

          </div>
        </div>
        <div class="more-choose">
          <p style="margin-left: 20px; margin-right: 5px;font-size: 14px;">半径（海里）</p>
          <el-input-number size="medium" v-model="radius" :min=1></el-input-number>
        </div>

        <div class="more-choose">
          <p style="margin-left: 20px; margin-right: 32px;font-size: 14px;">船舶类型</p>
          <el-select v-model="value2024010300" placeholder="不限">
            <el-option
              v-for="item in boatType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </div>
        <div class="more-choose">
          <p style="margin-left: 20px; margin-right: 60px; font-size: 14px;">状态</p>

          <el-select v-model="value2024010301" placeholder="不限">
            <el-option
              v-for="item in boatStatus"
              :v-if="isLog"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </div>

        <div class="search-reset">
          <el-button type="primary" @click="ifLargerThanFive">查询</el-button>
          <el-button @click="resetSearchBoatAround" type="info" plain>重置</el-button>
        </div>

      </div>
    </div>
    <div class="sidebar" v-if="isShowMyFleet && fleetPage === 1">
      <button class="close-btn" @click="closeSidebar">✖</button>
      <div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <p style="text-align: left; color: #ffffff; font-size: 15px; margin-left: 10px; margin-bottom: 0;">我的船队</p>
          <!--2023 3.12 -->
          <el-button style="background-color: #333; color: #fff;margin-right: -130px;margin-bottom: -9px;" @click.stop="showNewFleetDialog">新建船队</el-button>
          <el-button style="background-color: #333; color: #fff;margin-right: 30px;margin-bottom: -9px;" @click="toMyFleet">管理船队</el-button>
        </div>

        <hr style="width: 100%; border: 1px solid #888888;">

        <div v-for="(fleet, fleetKey) in shipList" :key="fleetKey" class="search-result" @click="toFleetInfo(fleetKey)">
          <p>{{ fleetKey }} <strong>: <span style="margin-right: 20px;"></span> {{ fleet.name }}</strong></p>
          <p>船队内船舶数量:<span style="margin-right: 20px;"></span><b>{{fleet.ships.length}}</b>
<!--            3.14-->
            <el-popconfirm title="确定删除整只船队吗？" @confirm="deleteFleet(fleetKey)">
              <el-button style="background-color: #333; color: #fff;margin-left: 150px;margin-bottom: -9px;" slot="reference" @click.stop>删除船队</el-button>
            </el-popconfirm>
          </p>
        </div>




      </div>
    </div>
    <div class="sidebar" v-if="isShowMyFleet && fleetPage === 2">
      <button class="close-btn" @click="closeSidebar">✖</button>
      <div>
        <div style="display: flex; justify-content: space-between; align-items: center;">
          <p style="text-align: left; color: #ffffff; font-size: 15px; margin-left: 10px; margin-bottom: 0;">{{this.selectedFleet.name}}</p>

          <el-button style="background-color: #333; color: #fff;margin-right: -180px;margin-bottom: -9px;" @click.stop="toMyFleet">船队设置</el-button>
          <el-button style="background-color: #333; color: #fff;margin-right: 30px;margin-bottom: -9px;" @click="changeFleetPage(1)">返回</el-button>
        </div>

        <hr style="width: 100%; border: 1px solid #888888;">
        <!-- 3.13 -->

        <p class="hover-text" @click="changeFleetPage(1)"> << 返回上一级</p>
        <div v-for="(ship, shipIndex) in selectedFleet.ships" :key="shipIndex" class="search-result" @click="updateSearchQuery(ship)">
          <div style="display: flex; justify-content: space-between; align-items: center;">
            <p>{{ shipIndex }} <strong>: <span style="margin-right: 20px;"></span> {{ ship.name }}</strong></p>

            <el-popconfirm title="确定移出该船吗？" @confirm="removeShip(selectedFleetKey, shipIndex)">
              <el-button style="background-color: #333; color: #fff;" slot="reference" @click.stop>移出船队</el-button>
            </el-popconfirm>
          </div>
          <p>MMSI: {{ ship.mmsi }}<span style="margin-right: 40px;"></span>IMO: {{ ship.imo }}</p>
        </div>




      </div>
    </div>
    <el-dialog :visible.sync="addNewFleet" title="新建船队名称">
      <el-input v-model="newFleetName" placeholder="请输入船队名称"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addNewFleet = false;">取消</el-button>
        <el-button :plain="true" type="primary" @click="addFleet(newFleetName);">确认</el-button>
      </span>
    </el-dialog>
  </div>




</template>

<script>
import 'ol/ol.css';
import { Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import MapboxLanguage from '@mapbox/mapbox-gl-language';


import buttonSearch from "@/components/searchComponents/buttonSearch";
import { getLocationBoat } from "@/utils/api";
import {getAISnowByMMSI} from "@/utils/api";
import {getBoatURL, getTrackListByMMSI, getFleetByUserID} from "@/utils/api";
import L, { Handler } from 'leaflet';
// import bus from '@/assets/js/bus';
import "../../utils/mapbox/lg.js";
import "../../utils/leaflet.canvas-markers.js";
import "../../utils/leaflet.polylineDecorator.js";

import "../../utils/L.LabelTextCollision-remix.js";

import DouglasPeuker from "@/utils/DouglasPeuker";

export default {
  props: {
    isShowMyFleet: Boolean // 或者使用更详细的验证
  },
  inject: ['showMyPartInfo'], // 注入 showMyInfo 数据
  name: "SearchBox",
  components: {
    buttonSearch,
  },
  mounted() {
    this.initMap();

  },
  data() {
    return {
      key: "289ecbf7bf4e4d00f5e53f5351be9717",
      newFleetName:"",
      addNewFleet:false,
      shipList: {
        fleet1: {
          name: "船队1",
          aisChange:false,
          inOut:false,
          area:false,
          weather:false,
          ships: [
            { imo: "9648714", mmsi: "432855000", name: "PRELUDE" },
            { imo: "I1234568", mmsi: "M1234568", name: "探索二号" },
            { imo: "I1234569", mmsi: "M1234569", name: "探索三号" }
          ]
        },
        fleet2: {
          name: "船队2",
          aisChange:false,
          inOut:false,
          area:true,
          weather:true,
          ships: [
            { imo: "I2234567", mmsi: "M2234567", name: "勇者一号" },
            { imo: "I2234568", mmsi: "M2234568", name: "勇者二号" },
            { imo: "I2234569", mmsi: "M2234569", name: "勇者三号" }
          ]
        },
        fleet3: {
          name: "船队3",
          aisChange:true,
          inOut:false,
          area:false,
          weather:false,
          ships: [
            { imo: "I3234567", mmsi: "M3234567", name: "一号" },
            { imo: "I3234568", mmsi: "M3234568", name: "二号" },
            { imo: "I3234569", mmsi: "M3234569", name: "三号" }
          ]
        }
      },
      group2: null,
      markerBoat: null,
      userName:null,
      isShip:true,
      myLeafletMap: null,
      marker: null,
      boatNewInfo:'',
      boatType: [
        {
          value: '',
          label: '不限'
        }, {
          value: '1',
          label: '集装箱船',
          // disabled: true
        }, {
          value: '2',
          label: '散货船'
        }, {
          value: '3',
          label: '邮轮'
        }, {
          value: '4',
          label: '杂货船'
        },{
          value: '5',
          label: '气槽船（LNG\\LPG等）'
        },
        {
          value: '6',
          label: '沥青运输船'
        },
        {
          value: '7',
          label: '滚装船'
        },
        {
          value: '8',
          label: '客船'
        },
        {
          value: '9',
          label: '工程服务船'
        },{
          value: '10',
          label: '近海作业船'
        },{
          value: '11',
          label: '拖轮'
        },{
          value: '12',
          label: '渔船'
        },{
          value: '13',
          label: '专用船'
        },{
          value: '14',
          label: '游艇'
        },{
          value: '15',
          label: '其他液货船'
        },{
          value: '16',
          label: '其他类型'
        },{
          value: '17',
          label: '未知类型干货船'
        },{
          value: '18',
          label: '未知类型液货船'
        },],
      value2024010300: '',
      boatStatus: [
        {
          value: '',
          label: '不限'
        },
        {
          value: '0',
          label: '在航'
        }, {
          value: '1',
          label: '锚泊',
          disabled: true
        }, {
          value: '2',
          label: '系泊'
        }, {
          value: '3',
          label: '其他'
        }],
      value2024010301: '',
      radius:5,
      images: [
        // require("@/assets/img/pre/img1.jpg"),
        // require("@/assets/img/pre/img2.jpg"),
        // require("@/assets/img/pre/img3.jpg"),
        // 在这里添加更多图片路径
      ],
      currentIndex: 0, // 当前显示的图片索引
      valueDataHistory: '',
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      value1: '',
      value2: '',
      currentTab: 'current',  // 默认选中的功能栏
      hoveredTab: null,       // 鼠标悬停的功能栏
      selectedItem: {

      }, // 存储被选中的船舶或港口信息
      searchQuery: "",
      tempQuery:"",
      searchResults: {

      }, // 用于存储搜索结果的数组
      isVisible: false,
      isVisible2: false,
      isShowMyFleet:false,
      fleetPage:1,
      selectedFleet:"",
      selectedFleetKey: "",
    };
  },

  computed: {
    isLog() {
      return this.$store.state.isLog;
    },
    getMyPartInfo(){
      this.userName = localStorage.getItem('username');
      return this.$store.state.showMyPartInfo;
    }
  },



  watch: {
    // 监听isShowMyFleet prop的变化
    isShowMyFleet(newVal) {
      if (newVal) { // 如果isShowMyFleet为true
        this.isVisible = false; // 更新isVisible为false
        this.isVisible2 = false; // 更新isVisible2为false
      }
    }
  },

  methods: {

    async getFleetInfo(){
      if(this.isLog){
        const userID = localStorage.getItem('userID');
        const response = getFleetByUserID({userID});
        this.shipList = response.data.resultData;
      }
    },
    changeFleetPage(pageNum){
      this.fleetPage = pageNum;
    },
    // 3.12
    toFleetInfo(index){
      this.selectedFleet = this.shipList[index];
      this.selectedFleetKey = index;
      this.fleetPage = 2;
      console.log(this.selectedFleet)
    },
    addFleet(fleetName) {

      const newFleet = {
        name: fleetName,
        aisChange: false,
        inOut: false,
        area: false,
        weather: false,
        ships: []
      };
      this.$set(this.shipList, `fleet${Object.keys(this.shipList).length + 1}`, newFleet);
      this.addNewFleet = false;
      this.$message({
        message: '创建船队成功',
        type: 'success'
      });
    },
    showNewFleetDialog() {
      this.addNewFleet = true;
    },
    initMap() {
      this.userName = localStorage.getItem('username');
      // Leaflet 地图初始化
      this.myLeafletMap = L.map(this.$refs.map, {
        attributionControl: false,
        zoomControl: true,  // 禁用放大缩小控件
        maxBounds: [
          [-90, -Infinity],  // 南极和无限制的西经度
          [90, Infinity]     // 北极和无限制的东经度
        ],
        minZoom: 3
      }).setView([39,116], 4);

      // 使用 Leaflet 加载 Mapbox 地图
      L.tileLayer(`https://api.mapbox.com/styles/v1/ohx/clil33vcn00g501pf022z1cme/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoib2h4IiwiYSI6ImNsaWwybmV1ODAxdzYzcms5Z3pvNzhvdTIifQ.YpFzvJXs5wJn5JVQcOc3cg`, {
        maxZoom: 18,
        tileSize: 512,
        zoomOffset: -1,
        minZoom: 3,
      }).addTo(this.myLeafletMap);


      // this.myLeafletMap = L.map(this.$refs.map, {
      //     minZoom: 3,
      //     maxZoom: 18,
      //     center: [40.02404009136253, 116.50641060224784], // 地图中心
      //     // zoom: 8, //缩放比列
      //     zoomControl: true,
      //     doubleClickZoom: false, // 禁用双击放大
      //     attributionControl: false, // 移除右下角leaflet标识
      //     preferCanvas: true,
      //   }).setView([40.02404009136253, 116.50641060224784], 4);
      //   const tdt_img = L.tileLayer(
      //     `http://t0.tianditu.gov.cn/vec_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${this.key}`
      //   ).addTo(this.myLeafletMap);
      //   const tdt_cia = L.tileLayer(
      //     `http://t0.tianditu.gov.cn/cva_w/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cva&STYLE=default&TILEMATRIXSET=w&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=${this.key}`
      //   );

      //   // const test1 = L.tileLayer(`https://tiles.geovis.online/base/v1/img/{z}/{x}/{y}?format=webp&tmsIds=w&token=25e695225f260e3bbe63dfb819302ba729d125aa30dcca669d9966ff52761adf`);
      //   // const test2 = L.tileLayer(`https://tiles.geovis.online/base/v1/cia/{z}/{x}/{y}?format=png&tmsIds=w&token=25e695225f260e3bbe63dfb819302ba729d125aa30dcca669d9966ff52761adf`);
      //   // const test3 = L.tileLayer(`https://tiles.geovis.online/base/v1/vec/{z}/{x}/{y}?format=webp&tmsIds=w&token=25e695225f260e3bbe63dfb819302ba729d125aa30dcca669d9966ff52761adf`);

      //   // L.layerGroup([test1, test2]).addTo(this.myLeafletMap);
      //   L.layerGroup([tdt_img, tdt_cia]).addTo(this.myLeafletMap);



      this.myLeafletMap.on("zoomend", this.updateNodes);
      this.myLeafletMap.on("moveend", this.updatePosition);
      this.myLeafletMap.on("mousemove", (e) => {
        // console.log(e.latlng);
      });
      this.myLeafletMap.createPane("labelPane");
      this.myLeafletMap.getPane("labelPane").style.zIndex = 200; //450，>默认的overlayPane的400，<tooltipPane的600，popupPane的7
    },
    confirmLogout() {
      this.$confirm('确认要登出吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.logout();
      }).catch(() => {
        // 用户点击取消或关闭对话框，不执行任何操作
      });
    },
    logout() {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('expireTime');
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      this.$store.commit('SET_LOGIN_STATE', false);
      this.$store.commit('SET_PART_INFO_STATE', false);
      // this.isUserLoggedIn();
    },
    focusOnLocation(y,x) {
      if (!this.isLog) {
        // 如果用户未登录，弹出提示框
        this.$message.warning('请先登录以使用该功能');
        return; // 中止函数执行
      }
      const location = [y, x];

      this.myLeafletMap.flyTo(location, 5, {'duration':0.25} );

      // 创建一个标记并添加到地图上
      // L.circleMarker(location, {
      //   color: 'red',
      //   fillColor: '#f03',
      //   fillOpacity: 1,
      //   radius: 3
      // }).addTo(this.myLeafletMap);

      let color = [
          "#5470c6",
          "#91cc75",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
        ];

      let svgIcon = `<svg style="transform: rotate(${this.boatNewInfo.cog / 10}deg);" t="1681434465649" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11680" width="64" height="64"><path d="M512.063218 0.90311s-133.66024 213.133897-133.660241 339.569259v592.692854c0 8.218299 2.041028 16.310162 5.9244 23.571164l23.842097 44.487186a41.673095 41.673095 0 0 0 36.738504 21.999753h139.404018c21.674634 0 0 0 0 0s16.255975-7.224878 28.339584-23.661475c7.82093-10.62057 21.638509-38.147355 21.638509-38.147355a59.406558 59.406558 0 0 0 6.014711-26.045685V340.472369c0-117.404265-128.241582-339.569259-128.241582-339.569259z" p-id="11681" fill="${
                color[5]
              }"></path></svg>`;
              // let svgIcon = `<svg style="transform: rotate(${360}deg);" t="1681434465649" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11680" width="64" height="64"><path d="M512.063218 0.90311s-133.66024 213.133897-133.660241 339.569259v592.692854c0 8.218299 2.041028 16.310162 5.9244 23.571164l23.842097 44.487186a41.673095 41.673095 0 0 0 36.738504 21.999753h139.404018c21.674634 0 0 0 0 0s16.255975-7.224878 28.339584-23.661475c7.82093-10.62057 21.638509-38.147355 21.638509-38.147355a59.406558 59.406558 0 0 0 6.014711-26.045685V340.472369c0-117.404265-128.241582-339.569259-128.241582-339.569259z" p-id="11681" fill="#1296db"></path></svg>`;
              let myIcon = L.icon({
                iconUrl: "data:image/svg+xml," + encodeURIComponent(svgIcon),
                
                iconSize: [31, 41], //  图标的大小    【值1，值2】 为具体你自定义图标的尺寸，比如我图标尺寸是32×52，表示该图标：宽度32像素，高度：52像素，那么值1:就是32，值2：就是52
                // shadowSize: [41, 41], //  影子的大小    【值1，值2】 为具体你自定义阴影图标的尺寸，比如我图标尺寸是41×41，表示该图标：宽度41像素，高度：41像素，那么值1:就是41，值2：就是41
                // iconAnchor: [24, 41], //  图标将对应标记点的位置 这个是重点， 【值1，值2】，值1：为图标坐标第一个值(即32)的一半，值2：为图标坐标第二个值(即52)
                // shadowAnchor: [4, 62], // 相同的影子
                // popupAnchor: [1, -24], // 该点是相对于iconAnchor弹出信息的位置  这个是我手动调出来的，文档默认原始值是[-1，-76]，我是去一半值，取一半值调出来的
              });

              if(this.markerBoat != null){
                this.myLeafletMap.removeLayer(this.markerBoat);
                this.markerBoat = null;
              }

              this.markerBoat = L.marker(location, {
                icon: myIcon,
              }).addTo(this.myLeafletMap);;
              let divCurrent = `
              <div style="backgroud-color: rgba(0, 0, 0, 0.2); font-size:13px">
                <div style="text-align:center;font-size:16px;font-weight:bolder;margin-bottom:5px">实时AIS信息</div>
                <table border="1" style="border-collapse: collapse; text-align: center" >
                  <tr>
                    <td style="padding:5px">MMSI</td>
                    <td style="padding:5px">${this.boatNewInfo.mmsi}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">船舶名称</td>
                    <td style="padding:5px">${this.boatNewInfo.shipname}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">中 文 名</td>
                    <td style="padding:5px">${this.boatNewInfo.shipnamecn}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">imo</td>
                    <td style="padding:5px">${this.boatNewInfo.imo}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">经度</td>
                    <td style="padding:5px">${this.boatNewInfo.x}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">纬度</td>
                    <td style="padding:5px">${this.boatNewInfo.y}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">时间</td>
                    <td style="padding:5px">${this.formatDate(this.boatNewInfo.time)}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">航迹向</td>
                    <td style="padding:5px">${this.boatNewInfo.cog / 10.0}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">船首向</td>
                    <td style="padding:5px">${this.boatNewInfo.true_head}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">航速</td>
                    <td style="padding:5px">${this.boatNewInfo.sog}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">船舶呼号</td>
                    <td style="padding:5px">${this.boatNewInfo.callsign}</td>
                  </tr>
                    <td style="padding:5px">船旗</td>
                    <td style="padding:5px">${this.boatNewInfo.flag}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">船长</td>
                    <td style="padding:5px">${this.boatNewInfo.length}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">船宽</td>
                    <td style="padding:5px">${this.boatNewInfo.width}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">吃水</td>
                    <td style="padding:5px">${this.boatNewInfo.draught}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">目的地代号</td>
                    <td style="padding:5px">${this.boatNewInfo.dest}</td>
                  </tr>
                    <td style="padding:5px">目的地</td>
                    <td style="padding:5px">${this.boatNewInfo.dest_en}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">目的地中文</td>
                    <td style="padding:5px">${this.boatNewInfo.dest_cn}</td>
                  </tr>
                </table>
                </div>
              </div>  
              `;
              divCurrent = `
              <div style="backgroud-color: rgba(50,159,238,0.9); font-size:13px">
                <div style="text-align:center;font-size:16px;font-weight:bolder;margin-bottom:5px">实时AIS信息</div>
                <table border="1" style="border-collapse: collapse; text-align: center" >
                  <tr>
                    <td style="padding:5px">MMSI</td>
                    <td style="padding:5px">${this.boatNewInfo.mmsi}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">船舶名称</td>
                    <td style="padding:5px">${this.boatNewInfo.shipname}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">经度</td>
                    <td style="padding:5px">${this.boatNewInfo.x}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">纬度</td>
                    <td style="padding:5px">${this.boatNewInfo.y}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">时间</td>
                    <td style="padding:5px">${this.formatDate(this.boatNewInfo.time)}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">航迹向</td>
                    <td style="padding:5px">${this.boatNewInfo.cog / 10.0}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">船首向</td>
                    <td style="padding:5px">${this.boatNewInfo.true_head}</td>
                  </tr>
                    <td style="padding:5px">航速</td>
                    <td style="padding:5px">${this.boatNewInfo.sog}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">吃水</td>
                    <td style="padding:5px">${this.boatNewInfo.draught}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">目的地代号</td>
                    <td style="padding:5px">${this.boatNewInfo.dest}</td>
                  </tr>
                    <td style="padding:5px">目的地</td>
                    <td style="padding:5px">${this.boatNewInfo.dest_en}</td>
                  </tr>
                  <tr>
                    <td style="padding:5px">目的地中文</td>
                    <td style="padding:5px">${this.boatNewInfo.dest_cn}</td>
                  </tr>
                </table>
                </div>
              </div>  
              `;
              this.markerBoat.bindPopup(divCurrent).openPopup();


    },

    formatDate(timestamp) {
      // const date = new Date(timestamp * 1000); // 将时间戳转换为毫秒
      // const year = date.getFullYear();
      // const month = date.getMonth() + 1; // getMonth() 返回0-11，表示1-12月
      // const day = date.getDate();

      // // 返回格式化的日期字符串
      // return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;


      var date = new Date(timestamp * 1000);
      var options = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' };
      var formatter = new Intl.DateTimeFormat('zh-CN', options);
      var stringTime = formatter.format(date);
      return stringTime;
    },

    openFullScreenLoading() {
      this.loading = this.$loading({
        lock: true,
        text: "正在努力加载中，请稍后",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.3)",
      });
    },
    closeLoading() {
      if (this.loading != null) {
        this.loading.close();
      }
    },

    goToMoreInfo() {
      if(!this.judgeIfLog())
        return false;
      const routeData = this.$router.resolve({
        name: 'moreInfo',
        query: { selectedItem: JSON.stringify(this.selectedItem) }
      });
      window.open(routeData.href, '_blank');
    },

    setDistance(distance){
      this.radius=distance;
    },
    resetSearchBoatAround()
    {
      this.value2024010301="";
      this.value2024010300="";
    },
    clearDateRange(){
      this.valueDataHistory = ['', ''];
    },
    setDateRange(rangeType) {
      const now = new Date();
      let start = new Date();

      switch (rangeType) {
        case 'day':
          start.setTime(now.getTime() - 24 * 60 * 60 * 1000);
          break;
        case 'week':
          start.setTime(now.getTime() - 7 * 24 * 60 * 60 * 1000);
          break;
        case 'month':
          start.setMonth(now.getMonth() - 1);
          break;
        case 'threeMonths':
          start.setMonth(now.getMonth() - 3);
          break;
      }

      this.valueDataHistory = [start, now];
      console.log(this.valueDataHistory);
    },
    changeTab(tab) {
      this.currentTab = tab;
    },
    hoverTab(tab) {
      this.hoveredTab = tab;
    },
    leaveTab() {
      this.hoveredTab = null;
    },
    // updateSearchQuery2(item) {
    //   this.searchQuery = item.name;
    //   this.selectedItem = item;
    //   this.isShip = true;
    //   this.getNewBoatInfo();
    //
    // },
    updateSearchQuery(item) {
      this.searchQuery = item.name;
      this.selectedItem = item;
      this.isShip = true;
      this.getNewBoatInfo();

    },
    updatePortSearchQuery(item) {
      this.searchQuery = item.name;
      this.selectedItem = item;
      this.isShip = false;
      this.focusOnLocation(this.selectedItem.lat,this.selectedItem.lon);
    },
    translateType(type) {
      const typeMap = {
        'Offshore Support Vessel': '海上支援船',
        // 添加其他类型的映射
      };
      return typeMap[type] || type;  // 如果找不到映射，就返回原始类型
    },
    translateStatus(status) {
      const statusMap = {
        'active': '在航',
        // 添加其他类型的映射
      };
      return statusMap[status] || '未知';  // 如果找不到映射，就返回原始类型
    },
    translateFlag(flag) {
      const flagMap = {
        'Australia': '澳大利亚',
        // 添加其他类型的映射
      };
      return flagMap[flag] || flag;  // 如果找不到映射，就返回原始类型
    },
    async searchBoat() {
      // if(this.searchQuery.trim() === ''|| /^[0-9]*$/.test(this.searchQuery) == false || this.searchQuery.length != 9){
      //   this.$notify.error({
      //             title:"错误",
      //             message: "请输入规范的MMSI",
      //           });
      //   return;
      // }
      // this.openFullScreenLoading();
      // let item = {};
      // item.name = this.searchQuery;
      // item.mmsi = this.searchQuery;
      // this.selectedItem = item;
      // this.isShip = true;
      // this.getNewBoatInfo();
      // return;





      if (this.searchQuery.trim() === '') {
        alert("请输入搜索内容");
        // ...其他逻辑...
      } else {
        try {
          // 等待异步操作完成并获取结果
          const response = await getLocationBoat({ keyword: this.searchQuery });
          this.tempQuery = this.searchQuery;
          this.searchResults = response.data.resultData;

          this.isVisible = true;
          this.isVisible2 = false;
          this.$emit('update:isShowMyFleet', false);
        } catch (error) {
          console.error("搜索请求出错: ", error);

        }
      }
    },
    async getNewBoatInfo(){
      this.clearGroup();
      if(this.markerBoat != null){
        this.myLeafletMap.removeLayer(this.markerBoat);
        this.markerBoat = null;
      }
      try {
        // console.log(this.selectedItem.mmsi);
        const response = await getAISnowByMMSI({mmsi:this.selectedItem.mmsi});
        this.boatNewInfo = response.data.resultData;
        this.focusOnLocation(this.boatNewInfo.y,this.boatNewInfo.x);
        this.closeLoading();
      } catch (error) {
        console.error("搜索请求出错: ", error);
        this.$notify.error({
                  title:"错误",
                  message: "没有查询到该船",
                });
        this.closeLoading();
      }
    },
    /**/
    async getBoatImgURL(){
      if(this.isLog){
        try {
          const response = await getBoatURL({boatKeyWordList:[this.boatNewInfo.shipname],pageSize:10,pageNum:1});
          this.images = response.data.resultData.records;
          // console.log(this.boatNewInfo.shipname);
          // console.log('111111111111111111111111111111111');
          // console.log(this.images);
          // console.log(response.data.resultData.records);
        } catch (error) {
          console.error("搜索请求出错: ", error);

        }
      }else{
        this.images = [
          require("@/assets/img/loginWarning.png"),
        ]
      }

    },
    aroudBoat() {
      this.isVisible2 = true;
      this.$emit('update:isShowMyFleet', false);
      this.isVisible = false;
    },
    /**/
    clearSearch() {
      this.searchQuery = '';
      this.selectedItem = null;
      this.clearGroup();
      if(this.markerBoat != null){
        this.myLeafletMap.removeLayer(this.markerBoat);
        this.markerBoat = null;
      }
      this.myLeafletMap.flyTo([39,116], 4, {'duration':0.25} );
    },
    /**/
    closeSidebar() {
      this.isVisible = false;
      this.isVisible2 = false;
      this.$emit('update:isShowMyFleet', false);
    },
    useShowMyPartinfo() {
      // console.log(this.useShowMyPartinfo());
      return this.showMyPartInfo;
    },
    handlerConfirm(times){
      this.setDateRange(times);
      this.ifMoreThanSeven();
    },
    judgeIfLog(){
      if (!this.isLog) {
        // 如果用户未登录，弹出警告框
        this.$alert('请先登录以使用该功能', '提示', {
          confirmButtonText: '确定',
          type: "warning"
        })
        return false;
      }
      return true;
    },
    ifLargerThanFive()
    {
      if(this.radius >= 5 || this.value2024010301 || value2024010300){
        if (!this.isLog) {
          // 如果用户未登录，弹出警告框
          this.$alert('您尚未登录，不可查询半径大于5海里的船舶，不可指定船舶状态和类型', '提示', {
            confirmButtonText: '确定',
            type: "warning"
          })
        }
      }
    },
    ifMoreThanSeven(){
      const [startDate, endDate] = this.valueDataHistory;
      if (startDate && endDate) {
        const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
        const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        if(daysDifference > 7 && !this.isLog){
          // 如果用户未登录，弹出警告框
          this.$alert('您尚未登录，不可查询大于7天的船舶轨迹', '提示', {
            confirmButtonText: '确定',
            type: "warning"
          })
        }else{
          this.getShipTrack(this.selectedItem.mmsi, startDate.getTime()/1000, endDate.getTime()/1000);
        }
      }
    },
    getShipTrack(mmsi, startdt, enddt){
      // console.log(mmsi, startdt, enddt)
      this.openFullScreenLoading();
      getTrackListByMMSI({
        mmsi: mmsi + "",
        startdt: startdt + "",
        enddt: enddt + "",
      })
        .then((res) => {
          this.tarckList = res.data.resultData;
          let listArr = [];
          this.tarckList.forEach((object) => {
            listArr.push([object.latitude, object.longitude])
          })
          this.myLeafletMap.fitBounds(listArr,  {'duration':0.25});
          this.figureOutTrack(this.tarckList);
          this.closeLoading();
        })
        .catch((e)=>{
          this.$message.warning('网路错误 ');
          this.$notify.error({
                  title:"错误",
                  message: "暂无轨迹",
                });
          this.closeLoading();
        })
    },
    toMyInfo() {
      // console.log('正在登录');
      if(!this.judgeIfLog())
        return false;
      const routeData = this.$router.resolve({
        name: 'myinfo',
        query: { num: '1' } // 添加查询参数
      });
      window.open(routeData.href, '_blank');
    },

    toMyFleet(){
      // console.log('正在登录');
      if(!this.judgeIfLog())
        return false;
      const routeData = this.$router.resolve({
        name: 'myinfo',
        query: { num: '2' } // 添加查询参数
      });
      window.open(routeData.href, '_blank');
    },
    figureOutTrack(list) {
      let zoom = this.myLeafletMap.getZoom();
      // console.log("zoom is :" + zoom);

      if (this.group2 != null) {
        this.myLeafletMap.removeLayer(this.group2);
        this.group2 = null;
        console.log("clear");
      }


      if(this.labelRenderer){
        this.labelRenderer.clearLabel();
      }

      // add libing
      this.labelRenderer = new L.Label({ pane: "labelPane" });
      // 666
      this.labelRenderer.addTo(this.myLeafletMap);
      let dMaxList = {
        3: 222222.22222222222,
        4: 125000,
        5: 80000,
        6: 32000,
        7: 11000,
        8: 7000,
        9: 4500,
        10: 3000,
        11: 1000,
        12: 300,
        13: 200,
        14: 100,
        15: 1,
        16: 1,
        17: 1,
        18: 1,
      };



      let listAll = list;

      listAll = this.getAisTrackSort(listAll);

      list = DouglasPeuker(list, dMaxList[zoom]);
      list = this.getAisTrackSort(list);
      let layerList = [];

      console.error(listAll.length);
      console.error(list.length);

      let aa = [];
      listAll.forEach((item,i) => {
        aa.push([listAll[i].latitude, listAll[i].longitude])
      })

      let result = [];
      let aset = 0;

      for(let e = 0; e < list.length; e++){
        result[e] = [];
        for(let m = aset; m < listAll.length; m++){
          result[e].push(listAll[m]);
          if(listAll[m] == list[e]){
            aset = m;
            break;
          }
        }
      }

      result.shift();

      // console.log(result)


      for(let e = 0; e < result.length; e++){
        let cc = [];
        result[e].forEach((item,i) => {
          cc.push([item.latitude, item.longitude])
        })
        let arrowAll = L.polyline(
          cc,
          { color: "#165184", stroke: "1.5", weight: 1.5, smoothFactor: 0 }
        );
        let arrowHeadAll = L.polylineDecorator(arrowAll, {
          patterns: [
            {
              offset: "50%",
              repeat: 0,
              symbol: L.Symbol.arrowHead({
                pixelSize: 6,
                polygon: false,
                pathOptions: { stroke: true, color: "#165184", weight: 1 },
              }),
            },
          ],
        });

        layerList.push(arrowAll);
        layerList.push(arrowHeadAll);
      }



      for (let i = 0; i < list.length; i++) {
        if (i != list.length - 1) {

        }

        if (i == 0) {
          var polygon1 = new L.circleMarker([list[i].latitude, list[i].longitude], {
            radius: 5,
            color: "#0f0", //颜色

            fillColor: "#0f0",

            fillOpacity: 0.4, //透明度
          }).bindPopup(`
            <div style="backgroud-color: rgba(0, 0, 0, 0.2);width:200px ; font-size:13px">
                  <div style="text-align:center;font-size:16px;font-weight:bolder;margin-bottom:5px">轨 迹 信 息</div>
                  <table border="1" style="border-collapse: collapse; text-align: center; width:100%" >
                    <tr>
                      <td style="padding:5px;font-weight:bolder;">经度</td>
                      <td style="padding:5px">${list[i].longitude}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px;font-weight:bolder;">纬度</td>
                      <td style="padding:5px">${list[i].latitude}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">目的港</td>
                      <td style="padding:5px">${list[i].destination}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">航向</td>
                      <td style="padding:5px">${list[i].course / 10.0}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">MMSI</td>
                      <td style="padding:5px">${list[i].mmsi}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">速度</td>
                      <td style="padding:5px">${list[i].speed}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">时间</td>
                      <td style="padding:5px">${this.formatDate(list[i].time)}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">航行状态</td>
                      <td style="padding:5px">${this.getBoatState(list[i].status)}</td>
                    </tr>
                  </table>  

              </div>
          `);
        } else if (i != list.length - 1) {
          var polygon1 = new L.circleMarker([list[i].latitude, list[i].longitude], {
            radius: 5,
            color: "#a96", //颜色

            fillColor: "#fa1",

            fillOpacity: 0.9, //透明度
          }).bindPopup(`
            <div style="backgroud-color: rgba(0, 0, 0, 0.2);width:200px ; font-size:13px">
                  <div style="text-align:center;font-size:16px;font-weight:bolder;margin-bottom:5px">轨 迹 信 息</div>
                  <table border="1" style="border-collapse: collapse; text-align: center; width:100%" >
                    <tr>
                      <td style="padding:5px;font-weight:bolder;">经度</td>
                      <td style="padding:5px">${list[i].longitude}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px;font-weight:bolder;">纬度</td>
                      <td style="padding:5px">${list[i].latitude}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">目的港</td>
                      <td style="padding:5px">${list[i].destination}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">航向</td>
                      <td style="padding:5px">${list[i].course / 10.0}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">MMSI</td>
                      <td style="padding:5px">${list[i].mmsi}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">速度</td>
                      <td style="padding:5px">${list[i].speed}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">时间</td>
                      <td style="padding:5px">${this.formatDate(list[i].time)}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">航行状态</td>
                      <td style="padding:5px">${this.getBoatState(list[i].status)}</td>
                    </tr>
                  </table>  
            </div>
          `);

          polygon1.on("click", function(e){
            // console.log(e);
          })

        } else {
          var polygon1 = L.circleMarker(
            [list[list.length - 1].latitude, list[list.length - 1].longitude],
            {
              radius: 6,
              color: "red", //颜色

              fillColor: "#f03",

              // color: "#C23531",

              fillOpacity: 0.4, //透明度
            }
          ).bindPopup(`
            <div style="backgroud-color: rgba(0, 0, 0, 0.2);width:200px ; font-size:13px">
                  <div style="text-align:center;font-size:16px;font-weight:bolder;margin-bottom:5px">轨 迹 信 息</div>
                  <table border="1" style="border-collapse: collapse; text-align: center; width:100%" >
                    <tr>
                      <td style="padding:5px;font-weight:bolder;">经度</td>
                      <td style="padding:5px">${list[list.length - 1].longitude}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px;font-weight:bolder;">纬度</td>
                      <td style="padding:5px">${list[list.length - 1].latitude}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">目的港</td>
                      <td style="padding:5px">${list[i].destination}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">航向</td>
                      <td style="padding:5px">${list[i].course / 10.0}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">MMSI</td>
                      <td style="padding:5px">${list[i].mmsi}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">速度</td>
                      <td style="padding:5px">${list[i].speed}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">时间</td>
                      <td style="padding:5px">${this.formatDate(list[i].time)}</td>
                    </tr>
                    <tr>
                      <td style="padding:5px">航行状态</td>
                      <td style="padding:5px">${this.getBoatState(list[i].status)}</td>
                    </tr>
                  </table>  
                </div>


            `);

        }

        layerList.push(polygon1);

        this.labelRenderer.addLabel({
          text: this.timestampToTimeHMS(list[i].time), //标注内容
          position: {
            //标注坐标
            lat: list[i].latitude,
            lng: list[i].longitude,
          },
          font: {
            //标注字体
            size: 12,
            color: "#000000",
            family: "YaHei",
            bold: "bold",
          },
          background: {
            //标注背景颜色及padding
            visible: true,
            color: "rgba(255,255,255,0.5)",
            padding: 5,
          },
          border: {
            //标注边框颜色及宽度
            visible: false,
            color: "#000000",
            width: 5,
          },
          zoom: {
            //标注的显示级别
            min: 1,
            max: 18,
          },
        });
      }

      let that = this;

      // 666
      setTimeout(function () {
        that.myLeafletMap.removeLayer(that.labelRenderer);

        that.labelRenderer.addTo(that.myLeafletMap);
      }, 100);

      this.group2 = L.layerGroup(layerList);

      this.group2.addTo(this.myLeafletMap);

    },
    removeShip(fleetKey, shipIndex) {
      this.shipList[fleetKey].ships.splice(shipIndex, 1);
      this.selectedFleet = this.shipList[fleetKey];
    },
    getAisTrackSort(trackList, falg = true) {
      console.log("转换前");
      console.log(trackList);
      let last = trackList.length - 1;
      // console.log(trackList[last]);
      for (let i = last; i > 0; i--) {
        let aSelf = trackList[i - 1].longitude;

        let aMinus = aSelf - 360;

        let aPlus = aSelf + 360;

        let flag = this.getMinTrack(trackList[i].longitude, aMinus, aSelf, aPlus);

        // console.log((i-1) + " ：数据 " + trackList[i-1].dTime + " flagis " + flag  + " f: " + aMinus + " f: " + aSelf + " f: " + aPlus);

        if (flag == -1) {
          trackList[i - 1].longitude = aMinus;
        } else if (flag == 1) {
          trackList[i - 1].longitude = aPlus;
        } else {
        }
        if (falg) {
          trackList[i - 1].latlong = [trackList[i - 1].latitude, trackList[i - 1].longitude];
        }
      }
      console.log("转换后");
      console.log(trackList);

      // let taipingyang = true;

      // for(let i = 0; i < trackList.length; i++) {
      //   if(trackList[i].longitude > -100 && trackList[i].longitude < 120){
      //     taipingyang = false;
      //     break;
      //   }
      // }

      // console.error("太平洋  : " + taipingyang);

      // if(taipingyang){
      //   for(let i = 0; i < trackList.length; i++) {
      //     if(trackList[i].longitude < 0){
      //       trackList[i].longitude = trackList[i].longitude + 360;
      //     }
      //   }
      // }

      return trackList;
    },
    timestampToTimeHMS(timestamp) {
      var date = new Date(timestamp * 1000); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
      var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      // return Y + M + D;
      return Y + M + D + h + m + s;
    },
    deleteFleet(fleetKey) {
      console.log(fleetKey)
      this.$delete(this.shipList, fleetKey);
    },
    clearGroup(){
      if (this.group2) {
        this.myLeafletMap.removeLayer(this.group2);
        this.group2 = null;
      }
      if(this.labelRenderer){
        this.myLeafletMap.removeLayer(this.labelRenderer);
        this.labelRenderer = null;
      }
    },
    getMinTrack(num, a, b, c) {
      // console.log(`num is ${num} a is ${a} b is ${b} c is ${c}`)
      if (
        Math.abs(num - a) ==
        this.getMinNumber(Math.abs(num - a), Math.abs(num - b), Math.abs(num - c))
      ) {
        return -1;
      } else if (
        Math.abs(num - c) ==
        this.getMinNumber(Math.abs(num - a), Math.abs(num - b), Math.abs(num - c))
      ) {
        return 1;
      } else {
        return 0;
      }
    },
    getMinNumber(x, y, c) {
      console.log(`x is ${x} y is ${y} c is ${c}`);
      let a = x < y ? (x < c ? x : c) : y < c ? y : c;
      // console.log("最小的是" + a);
      return a;
    },
    updateNodes() {
      if(this.group2 != null){
        this.figureOutTrack(this.tarckList);
      }
    },
    updatePosition() {

    },
    addBoatToFleet(index) {
      const shipName = this.shipList[index].name; // 获取当前选择的船的名称
      this.$confirm(`确认要将这艘船加入"${shipName}"吗?`, '确认操作', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const shipInfo1 = {
          imo: this.selectedItem.imo,
          mmsi: this.selectedItem.mmsi,
          name: this.selectedItem.name
        };
        this.shipList[index].ships.push(shipInfo1);
        console.log('Adding boat to fleet with index:', index);
        // 在这里实现添加船到舰队的逻辑
        // 例如: this.shipList[index].addedToFleet = true; 或其他逻辑
      }).catch(() => {
        console.log('Add to fleet action cancelled');
        // 处理取消操作
      });
    },
    getBoatState(state) {
      state = parseInt(state);
      switch(state){
        case 0:
          return "主机动力航行";
        case 1:
          return "锚泊";
        case 2:
          return "未受令";
        case 3:
          return "机动性受限";
        case 4:
          return "吃水受限";
        case 5:
          return "系泊";
        case 6:
          return "搁浅";
        case 7:
          return "作业中";
        case 8:
          return "风帆动力航行";
        default:
          return "未知";
      }
    }

  },

};
</script>

<style lang="less" scoped>
.map {
  height: 100vh;
  width: 100vw;
  position: fixed !important;
}

/deep/ .leaflet-top{
  top: auto;
  bottom: 0;
  position: fixed;
  margin-bottom: 10px;

}
/deep/ .search-result {
  background-color: rgba(0,0,0,.3) !important;
  overflow: visible;
  p{
    color: #ffffff !important;
  }
}
.search-result:hover {
  background-color: rgba(0,0,0,.5) !important;
  // transform: scale(1.1); /*放大1.1倍*/
  font-size: 17px;
}

.search-layout {
  margin-left: 5px;
  position: absolute;
  top: 59px;
  left: 8px;
  z-index: 999;
  width: 370px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 5px;
}


.search-box {
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: 8px;
}

.icon-container {
  margin-right: 10px;
}

.icon-container i {
  cursor: pointer;
  font-size: 16px;
  color: #6666666;
}
.icon-container:hover i {
  color: #65788d; /* 悬停时的颜色 */
}

.search-input {
  flex: 1; /* 充满剩余空间 */
  padding: 15px;
  border: none;
  border-radius: 10px 0 0 10px; /* 左侧圆角 */
  background-color: rgba(238, 238, 238, 0.89);
  color: #000000;
  outline: none;
}

button-search {

  border-radius: 0 3px 3px 0; /* 右侧圆角 */
  background-color: #4caf50; /* 按钮背景颜色 */
  color: #ffffff; /* 按钮文字颜色 */
  cursor: pointer;
  height: 100%;
}

button-search:hover {
  background-color: #309fee;
  transition: background-color 0.3s ease;

}

.sidebar {
  position: fixed;
  top: 48px;
  right: 0px;
  width: 20vw;
  max-width: 400px;
  min-width: 300px;
  height: 92%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  box-shadow: -2px 0 5px 3px rgba(0, 0, 0, 0.4);
  z-index: 999;
  margin-right: 3px;
  padding-bottom: 20px;
}

.vf-div{
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 95%;
}

/* 隐藏默认的滚动条 */
// ::-webkit-scrollbar {
//     width: 0; /* Chrome/Safari */
// }

/* 创建新的滚动条样式 */
.vf-div::-webkit-scrollbar {
  width: 8px; /* 设置滚动条宽度 */
  height: 60px;
}

// /* 滚动条上按钮的样式 */
.vf-div::-webkit-scrollbar-button {
  display: none; /* 不显示按钮 */
}

// /* 滚动条轨道的样式 */
.vf-div::-webkit-scrollbar-track {
  background-color: #f5f5f5; /* 设置背景色 */
}

// /* 滚动条滑块的样式 */
.vf-div::-webkit-scrollbar-thumb {
  border-radius: 4px; /* 设置边角圆形化 */
  background-color: #ccc; /* 设置滑块颜色 */
}

// /* 当滑块被点击时的效果 */
.vf-div::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* 设置滑块鼠标悬停时的颜色 */
}


.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  font-size: 1em;
  cursor: pointer;
  color: #ffffff;
}

.search-result {
  /* 调整每个结果的高度，例如使其基于内容自适应 */
  margin-bottom: 0px;

  background-color: #e1ffff;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 999;
  /* 可以添加 max-height 或具体的 height 根据需求调整 */
}

.search-result:hover {
  background-color: #f8fde1;
  /* 悬停时的背景色 */
  cursor: pointer;
}

.search-result p {
  /* 减小段落文本的字体大小 */
  font-size: 0.8em; /* 或者使用具体的像素值，例如 12px */
  color: #65788d;
}

.extra-spacing {
  margin-right: 30px; /* 可根据需要调整右侧的间距 */
}

.info-box {
  width: 368px; /* 与搜索栏宽度相同 */
  height: 500px;//calc(100vh / 2); /* 屏幕高度的三分之一 */
  font-weight: bolder;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.43);
    color: #ffffff;
    border-radius: 0px 0px 5px 5px;
    // box-shadow: -2px 0 3px -1px gray, 0 0px 3px -1px gray, 0 2px 3px -1px gray, 2px 0 3px -1px gray;
  padding: 0px;
  box-shadow: 0 2px 5px  rgba(0, 0, 0, 0.6);
  position: relative; /* 确保子元素相对于此定位 */
  // border: 1px solid #ccc; /* 添加边框 */
  overflow-y: auto; /* 在垂直方向上添加滚动条 */
  overflow-x:hidden;
}

.info-box::-webkit-scrollbar {
  width: 5px; /* 设置滚动条宽度 */
  height: 10px;
}

// /* 滚动条上按钮的样式 */
.info-box::-webkit-scrollbar-button {
  // display: none; /* 不显示按钮 */
}

// /* 滚动条轨道的样式 */
.info-box::-webkit-scrollbar-track {
  background-color: transparent; /* 设置背景色 */
  border-radius: 4px; /* 设置边角圆形化 */
}

// /* 滚动条滑块的样式 */
.info-box::-webkit-scrollbar-thumb {
  border-radius: 4px; /* 设置边角圆形化 */
  background-color: #f3eff4; /* 设置滑块颜色 */
  height: 5px;
}

// /* 当滑块被点击时的效果 */
.info-box::-webkit-scrollbar-thumb:hover {
  background-color: #999; /* 设置滑块鼠标悬停时的颜色 */
}


.category-bar {
  position: sticky;
  display: flex;
  top:0;
  justify-content: space-around; /* 平均分配每个分类 */
  height: 8%; /* 分类功能栏占高度10% */
  // background-color: #f7f7f7; /* 背景色，根据图片调整 */
  z-index: 1111111;

}

.category-item {
  flex: 1;
  text-align: center;
  cursor: pointer;
  padding: 8px 0; /* 调整上下内边距 */
  font-size: 14px; /* 设置分类项目的字体大小 */
  /*font-weight: bold; !* 字体加粗 *!*/
  border-right: 1px solid #ccc;
  position: relative; /* 相对定位，为伪元素做准备 */
  background-color: #309fee;
}

.category-item:last-child {
  border-right: none; 
}

.category-item.active::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 50%; /* 伪元素宽度 */
  height: 2px; /* 伪元素高度 */
  background-color: #ffffff; /* 伪元素背景色，根据图片调整 */
  color: #309fee;
}

.category-item:hover {
  background-color: #103478; /* 悬停时的背景色，根据图片调整 */
}

.update-time {
  background-color: #2a3f7e; /* 暗淡的浅黄色背景 */
  padding: 1px; /* 或者根据您的设计需求调整内边距 */
  width: 100%; /* 或者您希望的固定宽度 */
  overflow: hidden; /* 隐藏溢出的文本 */
  white-space: nowrap; /* 防止文本换行 */
}

.update-time p {
  display: inline-block;
  // color: coral;
  // background-color: #2a3f7e;
  font-size: 0.8em;
  /* 动画名称，持续时间，迭代次数，速度曲线 */
  animation: scroll-left 10s linear infinite;
}

/* 定义滚动动画 */
@keyframes scroll-left {
  0% {
    transform: translateX(50%); /* 从右边开始 */
  }
  100% {
    transform: translateX(0%); /* 向左滚动文本的宽度 */
  }
}
.more-choose{
  // background-color: rgba(255, 255, 255, 0.7); /* 暗淡的浅黄色背景 */
  padding: 1px; /* 或者根据您的设计需求调整内边距 */
  /*margin-bottom: 10px; !* 如果需要在块与下面内容之间加入间隔 *!*/
  display: flex;
  align-items: center; /* 确保子元素垂直居中 */
}
.to-space{
  background-color: #ffffff; /* 暗淡的浅黄色背景 */
  padding: 1px; /* 或者根据您的设计需求调整内边距 */
  /*margin-bottom: 10px; !* 如果需要在块与下面内容之间加入间隔 *!*/
  display: flex;
  align-items: center; /* 确保子元素垂直居中 */
}
.flex-container {
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}

.leftHalf, .rightHalf {
  width: 50%; /* 每个半区占用50%的宽度 */
  /* 如果你希望内容有更多的空间，可以略微减少宽度，例如 48% 然后给每个部分增加内边距 */
}

.item-row {
  display: flex;
  justify-content: space-between; /* 使两个子元素分布在两侧 */
  align-items: center;
  font-size: 13px;
  color: #ffffff;
}

.item-label {
  text-align: left; /* 左对齐文本 */
  margin-left: 8px;
}

.item-value {
  text-align:center; /* 右对齐文本 */
  margin-left: auto; /* 推送到右侧 */
  flex: 1; /* 允许这个元素增长，填充剩余空间 */
}

.button-container {
  display: flex;
  justify-content: flex-end; /* 右对齐子元素 */
  margin-left: auto; /* 自动填充左边的空间，将按钮推到右侧 */
  margin-right: 20px;
}
.button-container-two .el-button {
  margin-right: 10px; /* 设置右边距，可以根据需要调整 */
  width: 60px; /* 设置固定宽度 */
  height: 35px; /* 设置固定高度 */
}

.button-container-two .el-button:last-child {
  margin-right: 0; /* 确保最后一个按钮没有额外的右边距 */
}

.button-container-two {
  display: flex;
  justify-content: space-between; /* 使元素分布在两端 */
  margin-top: 10px;
  margin-bottom: 5px;
  margin-left: 4%;
}
.search-reset{
  background-color: rgba(238, 238, 238, 0.89); /* 暗淡的浅黄色背景 */
  padding: 1px; /* 或者根据您的设计需求调整内边距 */
  /*margin-bottom: 10px; !* 如果需要在块与下面内容之间加入间隔 *!*/
  display: flex;
  align-items: center; /* 确保子元素垂直居中 */
}

.search-reset .el-button {
  margin-right: 10px; /* 设置右边距，可以根据需要调整 */
  margin-left: 10px;
  margin-bottom: 5px;
  width: 70px; /* 设置固定宽度 */
  height: 35px; /* 设置固定高度 */
}


/* 特定的按钮大小样式，确保所有按钮具有相同的高度和宽度 */
.el-button--small {
  padding: 7px 5px; /* 调整以确保所有的小按钮具有相同的大小 */
}

/* 确保这是span的父元素的样式 */
.parent-container {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 100%; /* 或者指定的高度 */
}

/* 如果没有父容器，直接对span应用样式 */
.demonstration {
  display: block; /* 使span表现得像一个块级元素 */
  text-align: center; /* 文本水平居中 */
  width: 100%; /* 使span占据整个容器宽度 */
  /*line-height: !* 容器的高度，如果知道的话，以确保垂直居中 *!;*/
  font-size: 15px;
  color: #ffffff;
  margin-top: 30px; /* 上边距 */
  margin-bottom: 20px;
}

.custom-date-picker {
  width: 300px; /* 自定义宽度 */
  height: 36px; /* 自定义高度 */
  margin-top: 4px; /* 上边距 */
  margin-left: 30px; /* 左边距 */
}
.el-two-button {
  display: flex;
  justify-content: space-between; /* 使元素分布在两端 */
  margin-top: 20px;
  margin-bottom: 5px;
}

.choice-container {
  display: flex;
  justify-content: space-between; /* 使元素分布在两端 */
  align-items: center; /* 确保上下对齐 */
  margin: 10px 0; /* 根据需要调整外边距 */

}

/* 可选的：如果您希望选择框有固定的宽度 */
.el-select,
.el-input-number {
  width: 200px; /* 按需调整宽度 */
}

.infoBox {
  position: fixed;
  top: 48px;
  right: 0px;
  width: 200px;
  padding: 0 0 10px 0;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);
  /*padding: 10px;*/
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.4);
  z-index: 1002;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
}

.upPart, .downPart {
  flex: 1; /* 让两个部分均分容器高度 */
  /*border-bottom: 1px solid #ffffff; !* 添加分界线 *!*/
}
.upPart{
  background-color: rgba(0, 0, 0,0.9);
  border-bottom: 1px solid #ffffff;
}
.upPart p, .downPart p {
  z-index: 1002;
  color: #ffffff;
  margin: 0; /* 重置段落的外边距 */
  /*padding: 5px; !* 添加一些内边距 *!*/
}

.el-two-button22 {
  display: flex;
  justify-content: space-between; /* 使元素分布在两端 */
  margin-top: 10px;
  margin-bottom: 5px;
}

.hover-text {
  color: white;
  font-size: 14px;
  cursor: pointer; /* 鼠标悬停时有鼠标动作 */
}

.hover-text:hover {
  color: orange; /* 鼠标悬停时字体颜色变为橙色 */
}

.custom-dropdown-menu {
  /* 使用transform属性微调下拉菜单的位置 */
  transform: translateX(220%) translateY(5%);
}



/deep/ .leaflet-popup-tip{
  background-color: rgba(43,63,126,0.9) !important;
}


/deep/ .leaflet-popup-close-button{
  color: #ffffff !;
}

/deep/ .leaflet-popup-content-wrapper{
  background-color: rgba(43,63,126,0.9) !important;
  color: #ffffff;
}

</style>
